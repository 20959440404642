<template>
    <div class="user">
        <van-form @submit="onSubmit">
            <van-field
                v-model="customername"
                name="customername"
                maxlength="8"
                @blur="onBlur"
                placeholder="患者姓名"
                :rules="[{ required: true, message: '请输入患者真实姓名' }]"
            >
            <template #label>
                <span style="color: red;">*</span>
                患者姓名
            </template>
            </van-field>
            <van-field name="sex" label="性别" :rules="[{ required: true, message: '请选择患者性别' }]">
                <template #label>
                    <span style="color: red;">*</span>
                    性别
                </template>
                <template #input>
                    <van-radio-group v-model="sex" direction="horizontal">
                    <van-radio checked-color="#CF8C57" name="M">男</van-radio>
                    <van-radio checked-color="#CF8C57" name="F">女</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field
                v-model="phone"
                
                name="phone"
                label="手机号"
                placeholder="手机号"
                :rules="[{ required: true, message: '请输入患者手机号码' }]"
            >
                <template #label>
                    <span style="color: red;">*</span>
                    手机号
                </template>
            </van-field>
            <van-field
                v-model="idcard"
                @blur="countage(idcard)"
                name="idcard"
                label="身份证号"
                placeholder="身份证号"
               
            >
            
            </van-field>
            <van-field
                :readonly="true"
                clickable
                name="birthday"
                :value="birthday"
                label="出生日期"
                placeholder="请选择出生日期"
                :rules="[{ required: true, message: '请输入出生日期' }]"
                @click="showCalendar = true"
            >
            <template #label>
                <span style="color: red;">*</span>
                出生日期
            </template>
            <template #input>
                <div v-if="birthday"><span style="margin-right: 80px;">{{ birthday }}</span> {{ calculateAge(birthday) }}岁</div>
            </template>
            </van-field>
            <van-field
                
                clickable
                name="source"
                :value="source.itemname"
                label="患者来源"
                placeholder="请选择来源"
                @click="showSource = true"
            />
            <van-field
                v-model="allergichistory"
                name="allergichistory"
                rows="3"
                autosize
                label="过敏史"
                type="textarea"
                maxlength="200"
                show-word-limit
                placeholder="请输入过敏史"
            />
            <van-field
                style="margin-bottom: 80px;"
                v-model="medicalhistory"
                name="medicalhistory"
                rows="3"
                autosize
                label="既往史"
                type="textarea"
                maxlength="200"
                show-word-limit
                placeholder="请输入既往史"
            />
            <div class="submit" >
                <van-button round block type="info" native-type="submit" color="#CF8C57">注册</van-button>
            </div>
        </van-form>
        <van-popup v-model="showCalendar" position="bottom">
        
            <van-datetime-picker
                v-model="currentDate"
                type="date"
                title="选择出生日期"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="onConfirmCalendar"
                @cancel="showCalendar = false"
                />
        </van-popup>
        <van-popup v-model="showSource" position="bottom">
            <van-picker
            title="选择患者来源"
            show-toolbar
            :columns="showSourceList"
            @confirm="onSourceConfirm"
                @cancel="showSource = false"
            >
            <template #option="item">
                {{ item.itemname }}
            </template>
        </van-picker>
        </van-popup>
    </div>
</template>
<script>
import {wxtriagedictionarydataqry,wxtreatregisteredmember} from '@/api/medical'
import { mapGetters } from 'vuex'
import {Toast} from 'vant'
import dayjs from 'dayjs'
export default{
    computed: {
        ...mapGetters([
            'username','dispname','clinicid'
        ]),
    },
    data(){
        return{
            customername:'',
            phone:'',
            sex:'',
            idcard:'',
            birthday:'',
            allergichistory:'',
            medicalhistory:'',
            currentDate:new Date(1970,0, 1),
            source:{},
            minDate: new Date(1900, 0, 1),
            maxDate:new Date(),
            showCalendar:false,
            showSource:false,
            showSourceList: [],
        }
    },
    created(){
        this.$store.dispatch('hideOrShowNav', false)
        this.getdictionarydataqry()
    },

    methods:{
        onBlur() {

            const value = this.customername;
            const chineseReg = /[\u4e00-\u9fa5]/g; // 匹配汉字的正则
            const chineseLength = value.match(chineseReg)?.length || 0;
        
            if (chineseLength < 2) {
                Toast('汉字至少需要2个')
      
                this.customername = ''
            } else if (chineseLength > 8) {
                Toast('汉字最多输入8个')
      
                this.customername = ''
            }
        },
        onSubmit(val){
            let obj = this.showSourceList.filter(item => item.itemname == val.source)
            let sou = '';
            if(obj.length){
                sou = obj[0].itemcode
            }
            
            if(val.idcard && !this.validateIdCard(val.idcard)){
                Toast('身份证号码输入不合法！')
                return
            }
            if(!this.isValidChinesePhoneNumber(val.phone)){
                Toast('手机号输入错误！')
                return
            }

            let data = {
                username:this.username,
                customername:val.customername,
                sex:val.sex,
                phone:val.phone,
                idcard:val.idcard,
                brith:val.birthday,
                source:sou,
                allergichistory:val.allergichistory,
                medicalhistory:val.medicalhistory,

            }

            wxtreatregisteredmember(data).then(response =>{
        
                Toast({message:'注册会员成功！',onClose:()=>{
                        // 跳转页面
                        // location.reload()
                        this.$store.dispatch('changeIsRefresh', '1')
                        this.$router.push({path:'/patientList'})
                    }
                    
                })
            }).catch((err) => {
                // on cancel
                
                Toast.fail(err.response_head.retinfo.retmsg)
            });
        },
        onConfirmCalendar(date){
 
            this.birthday = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            this.showCalendar = false
        },
        onSourceConfirm(val){

            this.source = val
            this.showSource = false
        },
        calculateAge(birthDate) {
            var today = new Date();
            var birthDateObj = new Date(birthDate);
            
            var age = today.getFullYear() - birthDateObj.getFullYear();
            var monthDiff = today.getMonth() - birthDateObj.getMonth();
            var dayDiff = today.getDate() - birthDateObj.getDate();
            
            if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
                age--;
            }
            
            return age;
        },
        // 查询字典
        getdictionarydataqry(){
            let data = {
                username:this.username,
                dictcode:'CUSTOMER_SOURCE'
            }
            wxtriagedictionarydataqry(data).then(response =>{
         
                this.showSourceList = response.response_body.list
            })
        },
        isValidChinesePhoneNumber(phoneNumber) {
            return /^1[3-9]\d{9}$/.test(phoneNumber);
        },
        countage(idCard){
            if (15 == idCard.length) {
                    var year = idCard.substring(6, 8);
                    var month = idCard.substring(8, 10);
                    var day = idCard.substring(10, 12);
                    var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
                    this.birthday =  dayjs(temp_date).format('YYYY-MM-DD')

                } else if (18 == idCard.length) {
                    var year = idCard.substring(6, 10);
                    var month = idCard.substring(10, 12);
                    var day = idCard.substring(12, 14);
                    var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
     
                    this.birthday =  dayjs(temp_date).format('YYYY-MM-DD')
                }
        },
         validateIdCard(idCard) {
                var result = true;

                if (15 == idCard.length) {
                    var year = idCard.substring(6, 8);
                    var month = idCard.substring(8, 10);
                    var day = idCard.substring(10, 12);
                    var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
           
                    // 对于老身份证中的你年龄则不需考虑千年虫问题而使用getYear()方法
                    if (temp_date.getYear() != parseFloat(year) || temp_date.getMonth() != parseFloat(month) - 1 || temp_date.getDate() != parseFloat(day)) {
                        result =  false;
                    }
                } else if (18 == idCard.length) {
                    var year = idCard.substring(6, 10);
                    var month = idCard.substring(10, 12);
                    var day = idCard.substring(12, 14);
                    var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
          
                    // 这里用getFullYear()获取年份，避免千年虫问题
                    if (temp_date.getFullYear() != parseFloat(year) || temp_date.getMonth() != parseFloat(month) - 1 || temp_date.getDate() != parseFloat(day)) {
                        result = false;
                    }
                } else {
                    result = false;
                }

                return result;
            
        }
    }
}
</script>
<style lang="scss" scoped>
.submit{
    padding: 20px;
    position: fixed;
    bottom: 0;
    left: 0 ;
    right: 0;
    background-color: #fff;
}
.user{
    .van-field__body{
       .van-field__control{
            color: #ccc !important;
        }
    }
    ::v-deep .van-field__control::-webkit-input-placeholder {
        color: #ccc;
        -webkit-text-fill-color: currentColor;
    }
}
</style>